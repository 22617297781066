import React, { useEffect } from "react";
import "./Privacy.css";

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>
                We respect your privacy and are committed to protecting any personal
                information you may share with us. This Privacy Policy outlines how we
                collect, use, and safeguard your data when you use our website, <strong>Click-n-Learn.com</strong>.
            </p>
            <h2>1. Information We Collect</h2>
            <p>
                We do not collect any personally identifiable information (PII) unless you
                voluntarily provide it (e.g., via a contact form or email). However, we may
                collect non-personally identifiable information through analytics tools, such as:
            </p>
            <ul>
                <li>Browser type</li>
                <li>Device information</li>
                <li>IP address (anonymized where possible)</li>
                <li>Pages visited and time spent on the site</li>
            </ul>

            <h2>2. Use of Analytics Tools</h2>
            <p>
                We use analytics tools (e.g., Google Analytics) to understand how users
                interact with our website and improve its functionality. These tools may
                use cookies to collect information about your activity on our site. You can
                disable cookies in your browser settings if you prefer not to participate in such analytics.
            </p>

            <h2>3. Use of Collected Information</h2>
            <p>
                Any information we collect is used solely to:
            </p>
            <ul>
                <li>Enhance website performance</li>
                <li>Understand user preferences</li>
                <li>Respond to inquiries sent via contact forms or email</li>
            </ul>
            <p>We will never sell or share your information with third parties for marketing purposes.</p>

            <h2>4. Third-Party Services</h2>
            <p>
                We may use third-party services to enhance your experience, such as:
            </p>
            <ul>
                <li>
                    <strong>Analytics tools:</strong> As mentioned above.
                </li>
                <li>
                    <strong>Hosting providers:</strong> Our website is hosted on Bluehost, and backend services are hosted on DigitalOcean.
                </li>
            </ul>
            <p>
                These services may process data as part of their normal operations, but they are
                contractually obligated to handle your data securely.
            </p>

            <h2>5. Cookies</h2>
            <p>
                Cookies are small text files stored on your device. We may use cookies to:
            </p>
            <ul>
                <li>Improve your browsing experience</li>
                <li>Analyze website traffic</li>
            </ul>
            <p>You can control or disable cookies through your browser settings.</p>

            <h2>6. Data Retention</h2>
            <p>
                We do not retain personal data unless required for responding to your inquiries or
                as necessary for analytics purposes. Non-personally identifiable data is retained
                only as long as needed for website performance monitoring.
            </p>

            <h2>7. Security</h2>
            <p>
                We prioritize the security of your data. While no system can guarantee complete
                security, we use reasonable measures to protect your information from unauthorized
                access or disclosure.
            </p>

            <h2>8. Updates to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted
                on this page, with the updated date clearly indicated.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions about this Privacy Policy, please feel free to contact us at:
            </p>
            <p>
                Email: <a href="mailto:info@click-n-learn.com">info@click-n-learn.com</a><br />
                Website: <a href="https://click-n-learn.com">Click-n-Learn.com</a>
            </p>
        </div>
    );
};

export default Privacy;
