import React, { useEffect } from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="terms-container">
            <h1>Terms of Service</h1>
            <p><strong>Effective Date:</strong> November 16, 2024</p>

            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using the <strong>Click-n-Learn</strong> website or mobile app, you agree to comply with and be bound by these Terms of Service. 
                If you do not agree to these terms, please do not use our services.
            </p>

            <h2>2. Use of the Service</h2>
            <p>You may use the <strong>Click-n-Learn</strong> app and website for personal, non-commercial purposes only. You agree not to:</p>
            <ul>
                <li>Copy, distribute, or reverse-engineer any part of the app or website.</li>
                <li>Use the service for any illegal or unauthorized purpose.</li>
                <li>Interfere with or disrupt the service, servers, or networks connected to the service.</li>
            </ul>

            <h2>3. Intellectual Property</h2>
            <p>
                All content, features, and functionality on the <strong>Click-n-Learn</strong> website and app 
                (including but not limited to text, graphics, logos, and software) are the exclusive property of Rich and Cyndi at {' '}
                <strong>twoatsea.com</strong>. You may not reproduce, distribute, or create derivative works without prior written permission.
            </p>

            <h2>4. User-Generated Content</h2>
            <p>
                You are responsible for any content you upload or create within the app (e.g., custom word files). 
                By submitting content, you grant us a non-exclusive, royalty-free license to use, modify, or display your content for the purpose of operating the service.
            </p>

            <h2>5. Disclaimers</h2>
            <ul>
                <li><strong>No Guarantees:</strong> The <strong>Click-n-Learn</strong> service is provided on an "as-is" and "as-available" basis. We make no guarantees regarding its availability, accuracy, or reliability.</li>
                <li><strong>Limitation of Liability:</strong> We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of the service.</li>
                <li><strong>Third-Party Links:</strong> Our website or app may include links to third-party websites. We are not responsible for the content or practices of these websites.</li>
            </ul>

            <h2>6. Privacy</h2>
            <p>
                Your use of the service is also governed by our {' '}
                <a href="/privacy-policy" className="terms-link">Privacy Policy</a>, which explains how we collect and use your data.
            </p>

            <h2>7. Modifications to Terms</h2>
            <p>
                We reserve the right to modify these terms at any time. If changes are made, we will post the updated terms on this page. 
                Your continued use of the service after changes are posted constitutes acceptance of the updated terms.
            </p>

            <h2>8. Governing Law</h2>
            <p>
                These terms are governed by the laws of [Your Location or Jurisdiction], without regard to its conflict of law provisions.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions about these Terms of Service, please contact us at:
            </p>
            <p>
                Email: <a href="mailto:info@click-n-learn.com" className="terms-link">info@click-n-learn.com</a><br />
                Website: <a href="https://click-n-learn.com" className="terms-link">Click-n-Learn.com</a>
            </p>
        </div>
    );
};

export default TermsOfService;
