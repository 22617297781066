import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <p className="footer-text">© 2024 Rich West. All rights reserved.</p>
                <p className="footer-text">
                    Contact us: <a href="mailto:info@click-n-learn.com">info@click-n-learn.com</a>
                </p>
                <div className="footer-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-service">Terms of Service</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
