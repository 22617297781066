import { Link, useLocation } from "react-router-dom";
import "./Header.css";

function Header({ isMenuOpen, setIsMenuOpen }) {
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            <div className="header-content">
                <img
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    alt="Click-n-Learn Logo"
                    className="header-logo"
                />
                {location.pathname !== '/get-click-n-learn' && (
                    <Link to="/get-click-n-learn" className="get-click-n-learn-button" onClick={() => setIsMenuOpen(false)}>
                        Get Click-n-Learn
                    </Link>
                )}
            </div>
            <nav>
                <button className="hamburger" onClick={toggleMenu}>
                    <i className={isMenuOpen ? "fas fa-times" : "fas fa-bars"}></i>
                </button>
                <div className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                    <Link to="/download" onClick={toggleMenu}>Download</Link>
                    <Link to="/help" onClick={toggleMenu}>Help</Link>
                </div>
            </nav>
        </header>
    );
}
export default Header;