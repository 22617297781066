import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw';
import Footer from "./Footer";
import './HelpPage.css';

const HelpPage = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/HelpScreen.md')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to load the Markdown file');
        }
        return response.text();
      })
      .then((text) => setContent(text))
      .catch((error) => {
        console.error('Error loading Markdown:', error);
        setContent('Help content could not be loaded at this time.');
      });
  }, []);

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: 1.6, margin: '20px', maxWidth: '800px' }}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeSlug, rehypeRaw]} // Enables HTML rendering
      >
        {content}
      </ReactMarkdown>
      <Footer />
    </div>
  );
};

export default HelpPage;
